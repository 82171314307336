import React, { useState } from 'react';
import loadable from '@loadable/component';

import { motion, AnimatePresence } from 'framer-motion';

import { useInView } from 'react-intersection-observer';

import { wrapper } from './videoModule.module.css';

// SVG
import PlayIcon from '../../svg/play.svg';

const VideoComponent = loadable(() => import('../media/standardVideo'));

export const VideoModule = ({ video }) => {
  const [isActive, setActive] = useState(false);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="bg-black text-white my-8" ref={ref}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={`video-${isActive}`}
          className={`${wrapper} video-block aspect-w-16 aspect-h-9`}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          exit={{
            opacity: 0,
          }}
        >
          {!isActive && (
            <button
              className="absolute z-10 top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-40"
              onClick={() => setActive(true)}
            >
              <div className="text-white outline-btn flex items-center">
                <PlayIcon className="inline-block mr-4 w-4 h-4 fill-current" />
                Watch Video
              </div>
            </button>
          )}
          {inView && (
            <VideoComponent
              url={video}
              playing
              muted={!isActive}
              loop={!isActive}
              controls={isActive}
              width="100%"
              height="auto"
              onEnded={() => setActive(false)}
              playsinline={!isActive}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
