import React, { createContext, useContext, useState } from 'react';

export const AppContext = createContext({
  reverseNav: false,
  hideFooter: false,
  solidHeader: false,
});

export const PageWrapper = ({ children }) => {
  const [reverseNav, setReverseNav] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [solidHeader, setSolidHeader] = useState(false);

  return (
    <AppContext.Provider
      value={{
        reverseNav,
        setReverseNav,
        hideFooter,
        setHideFooter,
        solidHeader,
        setSolidHeader,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
