import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import '../css/main.css';

import { useLocation } from '@reach/router';
import { useAppContext } from '../state';

import { Header } from '../components/nav/header';
import { Footer } from '../components/nav/footer';

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const { hideFooter } = useAppContext();

  return (
    <>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <motion.main
          className="w-full absolute top-0 left-0"
          key={pathname}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: 'easeInOut',
            duration: 0.5,
            delay: 0.2,
          }}
        >
          {children}
          {!hideFooter && <Footer />}
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default Layout;
