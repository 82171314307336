import React from "react";

// Querys / Hooks
import { useSiteConfig } from "../../GraphQl/useSiteConfig";

// Svg
import LogoMark from "../../svg/logoMark.svg";
import Smarrt from "../../svg/smarrt.svg";

// Components
import { PortableTextBlock } from "../sanity/portableTextBlock";

export const Footer = () => {
  const { footerQuote, footerDetails } = useSiteConfig();

  return (
    <footer className="px-gutter py-20 bg-logic-navy text-white lg:py-32">
      <div className="md:flex md:justify-between md:flex-row-reverse">
        <div className="mb-10 md:mb-0">
          <div className="w-24 md:w-40 lg:w-52">
            <LogoMark />
          </div>
        </div>
        <PortableTextBlock
          text={footerQuote}
          className="prose max-w-none md:prose-xl md:pr-24 md:max-w-2xl lg:max-w-4xl"
        />
      </div>
      {/* Bottom Area */}
      <div className="md:grid md:grid-cols-2 md:mt-20">
        <div className="grid gap-y-6 my-12 md:my-0 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-20">
          {footerDetails.map((item) => (
            <PortableTextBlock {...item} className="prose opacity-70" />
          ))}
        </div>

        <p className="opacity-70 text-sm md:self-end md:text-left col-start-1 col-end-2">
          &copy; {new Date().getFullYear()} COPYRIGHT LOGIQ GROUP LTD
        </p>
        <div className="w-48 md:w-96 col-start-2 col-end-3  justify-self-end mt-10">
          <a href="https://www.rrtglobal.org/nz/" target="_blank" rel="noopener noreferrer">
            <Smarrt />
          </a>
        </div>
      </div>
    </footer>
  );
};
