import React from "react";
import PortableText from "react-portable-text";
import { PopupButton } from "@typeform/embed-react";

// Block Components
import { PageLink, PathLink, AnchorLink } from "../ui/links";
import { ImageModule } from "../modules/imageModule";
import { VideoModule } from "../modules/videoModule";
import { TestimonialModule } from "../modules/testimonialModule";

const serializers = {
  //removing this first line as causing weird additional bullet
  //ul: (props) => <ul {...props} className="list-disc pl-8 ml-0" />,
  ol: (props) => <ol {...props} className="list-disc  pl-8 ml-00" />,
  h1: (props) => <h1 {...props} className="font-sans" />, // eslint-disable-line
  h2: (props) => <h2 {...props} className="font-sans" />, // eslint-disable-line
  h3: (props) => <h3 {...props} className="font-sans" />, // eslint-disable-line
  h4: (props) => <h4 {...props} className="font-sans text-logic-teal" />, // eslint-disable-line
  h5: (props) => <h5 {...props} className="font-sans" />, // eslint-disable-line
  h6: (props) => <h6 {...props} className="font-sans" />, // eslint-disable-line
  "h1-light": (props) => <h1 {...props} />, // eslint-disable-line
  "h2-light": (props) => <h2 {...props} />, // eslint-disable-line
  "h3-light": (props) => <h3 {...props} />, // eslint-disable-line
  "h4-light": (props) => <h4 {...props} className="text-logic-teal" />, // eslint-disable-line
  "h5-light": (props) => <h5 {...props} />, // eslint-disable-line
  "h6-light": (props) => <h6 {...props} />, // eslint-disable-line
  "super-script": (props) => <sup>{props.children}</sup>,
  "sub-script": (props) => <sub>{props.children}</sub>,
  pageLink: (props) => <PageLink {...props} className="outline-btn" />,
  pageReference: ({ page, children }) => (
    <PageLink
      linkText={children}
      page={page}
      style={{ textDecoration: "underline" }}
      className="font-sans text-lg md:text-xl"
    />
  ),
  pathLink: (props) => <PathLink {...props} className="outline-btn" />,
  externalLink: (props) => (
    <a href={props.link} className="outline-btn">
      {props.linkText}
    </a>
  ),
  link: ({ href, url, children }) => {
    return (
      <a href={href ? href : url} rel="noreferrer" target="_blank">
        {children}
      </a>
    );
  },
  anchorLink: (props) => <AnchorLink {...props} className="outline-btn" />,
  normal: (props) => <p {...props} className="leading-relaxed" />,
  highlight: (props) => <span {...props} className="text-logic-teal" />,
  imageModule: (props) => <ImageModule {...props} />,
  videoModule: (props) => <VideoModule {...props} />,
  testimonial: (props) => <TestimonialModule {...props} />,
  typeformButton: (props) => (
    <PopupButton id={props.formId} className="outline-btn">
      {props.text}
    </PopupButton>
  ),
};
// eslint-disable-line

export const PortableTextBlock = ({ text, isAnimated = false, ...props }) => {
  return (
    text && (
      <PortableText
        content={text}
        serializers={serializers}
        renderContainerOnSingleChild={true}
        {...props}
      />
    )
  );
};
