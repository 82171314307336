import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// SVG
import PlayIcon from '../../svg/play.svg';

// Hooks
import { useScrollFreeze } from '../../hooks';

// Components
import { NavLink } from './navLink';
import { Link } from 'gatsby';

const MobileNavLink = ({ onClose, ...props }) => {
  const [isActive, setActive] = useState(false);

  return (
    <div
      role="button"
      tabIndex="-1"
      onClick={onClose}
      onKeyDown={onClose}
      className="relative pb-2"
    >
      <NavLink
        {...props}
        className={`text-3xl transition-all font-sans`}
        getProps={({ isPartiallyCurrent }) => setActive(isPartiallyCurrent)}
      />
      {isActive && (
        <motion.span
          style={{ height: '2px' }}
          className="w-full bg-logic-teal block absolute bottom-0"
          layoutId="line"
          initial={false}
        />
      )}
    </div>
  );
};

export const NavInner = ({ items, onClose, reverseNav }) => {
  useScrollFreeze();

  return (
    <motion.div
      className={`fixed top-0 left-0 h-screen w-screen px-gutter flex items-center justify-center z-30 bg-white ${reverseNav ? 'bg-white text-logic-navy' : 'bg-white text-logic-navy'
        }`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <nav className="grid gap-y-6 text-center">
        {items.map((item) => (
          <MobileNavLink {...item} onClose={onClose} />
        ))}
      </nav>
      <a
        onClick={onClose}
        onKeyDown={onClose}
        href='/contact'
        className="bg-logic-teal text-logic-navy absolute bottom-0 left-0 w-full text-center py-6 text-2xl flex justify-center items-center"
      >
        Lets get started
        <PlayIcon className="w-3 ml-5" />
      </a>
    </motion.div>
  );
};

export const MobileNav = ({ isOpen, ...rest }) => {
  return <AnimatePresence>{isOpen && <NavInner {...rest} />}</AnimatePresence>;
};
