import React, { useState } from "react";
import { Link } from "gatsby";

import { motion, AnimateSharedLayout } from "framer-motion";

import { NavLink } from "./navLink";

const DeskNavLink = (props) => {
  const [isActive, setActive] = useState(false);
  return (
    <div className="py-10 relative">
      {isActive && (
        <motion.span
          className="w-full h-1 bg-logic-teal block absolute top-0"
          layoutId="line"
          initial={false}
        />
      )}
      <NavLink
        {...props}
        className={`xl:text-sm transition-all ${isActive && "font-bold"}`}
        getProps={({ isPartiallyCurrent }) => setActive(isPartiallyCurrent)}
      />
    </div>
  );
};

export const DeskNav = ({ items }) => {
  return (
    <AnimateSharedLayout>
      <div className="hidden lg:flex items-center">
        <nav className="hidden lg:grid grid-flow-col gap-x-10 mr-10">
          {items?.map((item, i) => (
            <DeskNavLink key={i} {...item} />
          ))}
        </nav>
        <Link to="/contact" className="outline-btn text-sm">
          Lets get started
        </Link>
      </div>
    </AnimateSharedLayout>
  );
};
