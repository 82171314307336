export const usePadTop = size => {
  switch (size) {
    case 'small':
      return 'pt-10 md:pt-40';
    case 'medium':
      return 'pt-32 md:pt-64';
    case 'large':
      return 'pt-48 md:pt-96';
    default:
      return '';
  }
}
