import React from 'react';

export const TestimonialModule = ({ quote, author }) => {
  return (
    <div className="text-center testi">
      <h2>{quote}</h2>
      <h5 className="font-bold">{author}</h5>
    </div>
  );
};
