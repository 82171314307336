import React from 'react';

import { PageWrapper } from './src/state';

export const wrapRootElement = ({ element }) => {
  return <PageWrapper>{element}</PageWrapper>;
};

const transitionDelay = 800;

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else if (location.action !== 'POP') {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
  }
  return false;
};

export const onClientEntry = () => {
  (function(c, h, i, m, p) {
    m = c.createElement(h);
    p = c.getElementsByTagName(h)[0];
    m.async = 1;
    m.src = i;
    p.parentNode.insertBefore(m, p);
  })(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/f983c0649f636821f579ff3e1/1e97d14040fa2029da1b18cd9.js");
};