export const useSanityPath = (props) => {
  const { _type, main, slug } = props || {};

  function getPath(_type, slug) {
    switch (_type) {
      case "page":
        return `/${slug.current}`;
      case "blogPost":
        return `/insights/${slug.current}`;
      case "careerPage":
        return `/careers/${slug.current}`;
      default:
        return null;
    }
  }

  const path = getPath(_type, slug, main);

  return path;
};
