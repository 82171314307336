import { useState } from 'react';

export const useModal = (initial) => {
  const [isOpen, setOpen] = useState(initial);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const toggleModal = () => setOpen((prevState) => !prevState);

  // If you want to rename output & multiple instance use array
  return [isOpen, closeModal, openModal, toggleModal];

  // Named Props & no order go with a named
  // return { isToggled, setToggled, toggle }
};
