import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';

// SVG
import Logo from '../../svg/logo.svg';
import LogoNavy from '../../svg/logiq-navy.svg'

// Hooks & Querys
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import { useAppContext } from '../../state';

import { MenuToggle } from './menuToggle';
import { DeskNav } from './deskNav';
import { MobileNav } from './mobileNav';

export const Header = () => {
  const { mainNav } = useSiteConfig();
  const { reverseNav, solidHeader } = useAppContext();

  const [navOpen, setNavOpen] = useState(false);
  const [headerPinned, setHeaderPinned] = useState(false);

  const closeNav = () => setNavOpen(false);
  const toggleNav = () => setNavOpen((prev) => !prev);

  useEffect(() => {
    if (headerPinned) {
      document.documentElement.classList.add('header-pinned');
    } else {
      document.documentElement.classList.remove('header-pinned');
    }
  }, [headerPinned]);

  return (
    <>
      <Headroom
        style={{ zIndex: '1000' }}
        onPin={() => setHeaderPinned(true)}
        onUnpin={() => setHeaderPinned(false)}
        onUnfix={() => setHeaderPinned(false)}
      >
        <header
          className={`flex justify-between items-center px-gutter py-5 transition-all lg:py-0 xl:px-24 border-b ${!headerPinned && 'border-current'
            } ${reverseNav
              ? ` ${headerPinned ? 'bg-header-bg text-current' : 'text-white'}`
              : `text-logic-navy ${headerPinned && 'bg-white'}`
            }
            ${(solidHeader && !headerPinned) && 'bg-white text-logic-navy'}
            `}
        >
          <Link to="/" className="w-32 lg:w-44">
            {navOpen ? <LogoNavy /> : <Logo />}
          </Link>
          <DeskNav items={mainNav} />
          <MenuToggle isOpen={navOpen} onClick={toggleNav} className="lg:hidden text-logic-teal" />
        </header>
      </Headroom>
      <MobileNav isOpen={navOpen} onClose={closeNav} items={mainNav} reverseNav={reverseNav} />
    </>
  );
};
